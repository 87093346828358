.portfolio {
  text-align: center;
  background: $light-grey;
  padding: 5em 0;

  h1 {
    font-size: 4rem;
  }
}
.portfolio-items {
  display: flex;
  flex-wrap: wrap;
  @include container($max-width: 75em);
}

.portfolio-item {
  position: relative;
  overflow: hidden;
  flex: 1 1 20rem;
  margin: 0;
  padding: 0;

  .portfolio-image {
    display: block;
    width: 100%;
  }

  figcaption {
    position: absolute;
    top: 100%;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 2em;
    background: rgba($accent, 0.75);
    color: $white;
    text-align: left;
    display: flex;
    flex-direction: column;
    transition: top ease-in-out 250ms;
  }
  &:hover figcaption,
  &:focus figcaption {
    top: 0;
  }
}

.portfolio-link {
  color: white;
  font-weight: $fw-b;
  font-size: 1.1rem;
  text-decoration: none;
  border-bottom: 3px solid $white;
  align-self: flex-start;

  //   &::after {
  //       content: url();
  //       display: inline-block;
  //       height: .8em;
  //       width: .8em;
  //       margin-left: .5em
  //     }
}

.portfolio-description {
  display: none;

  .featured & {
    display: block;
  }
}

.portfolio-title {
  color: white;
  font-size: 2.5rem;
  margin-bottom: auto;

  .featured & {
    font-size: 5rem;
  }
}

// @supports (display: grid) {
//   .portfolio-items {
//     display: grid;
//     grid-template-columns: repeat(2, 1fr);
//   }

//   .portfolio-item.featured {
//     grid-column: 1 / span 2;
//     grid-row: 1 / span 2;
//   }

//   .featured {
//     .portfolio-title {
//       font-size: 5rem;
//     }

//     .portfolio-title {
//       display: bock;
//     }
//   }
// }

@supports (display: grid) {
  .portfolio-items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: 75%;
    grid-gap: 0.5em;

    @include mq {
      // grid-template-columns: repeat(3, 1fr);
    }
  }

  .portfolio-item.featured {
    grid-column: 1 / span 2;
    grid-row: 1 / span 2;
  }

  .featured {
    .portfolio-title {
      font-size: 5rem;
    }

    .portfolio-desc {
      display: block;
    }
  }
}
