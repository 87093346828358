// %container {
//   width: 90%;
//   margin: 0 auto;
//   max-width: 50em;
// }

// %clearfix {
//   &::after {
//     content: "";
//     display: block;
//     clear: both;
//   }
// }

@mixin container($width: 90%, $max-width: 50em) {
  width: $width;
  margin: 0 auto;
  max-width: $max-width;
}

@mixin clearfix {
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}

@mixin mq($width: 50em) {
  @media (min-width: $width) {
    @content;
  }
}
