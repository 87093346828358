.contact {
  @include container;
  padding: 5em 0;
  justify-content: center;

  h1 {
    text-align: center;
  }
}
form {
  display: flex;
  flex-direction: column;

  @supports (display: grid) {
    display: grid;
  }
}

input,
textarea {
  box-sizing: border-box;
  display: block;
  padding: 0.75em;
  font-size: 1.2rem;
  width: 100%;
  background: lighten($light-grey, 6%);
  border: 1px solid $grey;
}

label {
  display: block;
  margin-top: 1em;
  color: $dark-grey;
}

.contactSubmit {
  //   display: block;
  //   background: $accent;
  //   margin-top: 1em;
  //   margin-left: auto;
  //   max-width: 30%;
  display: inline-block;
  max-width: 30%;
  min-width: auto;
  margin-top: 1em;
  margin-left: auto;

  border: none;
  @include sans-serif($fs: 2.5rem, $color: $accent);
  padding: 0.5em 1.5em;
}
